
import { defineComponent, reactive, toRaw } from 'vue';
import {
  PlusOutlined,
  ReloadOutlined,
  ColumnHeightOutlined,
  SettingOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { queryRule } from '@/api/list/table-list';
import { Pagination, TableFilters, TableColumn } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';

const statusMap = {
  0: { text: '关闭', status: 'default' },
  1: { text: '运行中', status: 'processing' },
  2: { text: '已上线', status: 'success' },
  3: { text: '异常', status: 'error' },
};
const status = ['关闭', '运行中', '已上线', '异常'];

const baseColumns: TableColumn[] = [
  {
    title: '规则名称',
    dataIndex: 'name',
  },
  {
    title: '描述',
    dataIndex: 'desc',
  },
  {
    title: '服务调用次数',
    dataIndex: 'callNo',
    sorter: {
      multiple: 1,
    },
    align: 'right',
    customRender: ({ text }) => `${text} 万`,
    // mark to display a total number
    needTotal: true,
  },
  {
    title: '状态',
    dataIndex: 'status',
    slots: { customRender: 'status' },
    filters: [
      {
        text: status[0],
        value: '0',
      },
      {
        text: status[1],
        value: '1',
      },
      {
        text: status[2],
        value: '2',
      },
      {
        text: status[3],
        value: '3',
      },
    ],
  },
  {
    title: '上次调度时间',
    dataIndex: 'updatedAt',
    sorter: {
      multiple: 1,
    },
  },
  {
    title: '操作',
    dataIndex: 'action',
    slots: { customRender: 'action' },
  },
];
export default defineComponent({
  name: 'TableList',
  setup() {
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, { checkAll: true, needRowIndex: true });

    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryRule, {
      current: 1,
      pageSize: 10,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: false,
    });
    const handleTableChange = ({ current, pageSize }: Pagination, filters: TableFilters) => {
      setPageInfo({
        current,
        pageSize,
        ...filters,
      });

      reload();
    };
    const queryParam = reactive({
      ruleName: undefined,
      desc: undefined,
      callNo: undefined,
    });

    const handleSearch = () => {
      setPageInfo({
        ...toRaw(queryParam),
      });
      reload();
    };

    return {
      statusMap,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      queryParam,
      handleSearch,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
  },
});
